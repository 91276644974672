import * as React from "react"
import useMedia from "use-media"
import { useInView } from 'react-intersection-observer'
import "modern-css-reset"
import "../styles/global/_styles.scss"

import Seo from "../components/Seo/index"
import Header from "../components/Header/index"

import LineUp from "../components/LineUp/index"
import DetailMainVisual from "../components/DetailMainVisual/index"
import FloorMap from "../components/FloorMap/index"
import Outline from "../components/Outline/index"
import Contact from "../components/Contact/index"
import Footer from "../components/Footer/index"

// images
import mainVisualImagePC from '../images/jimbocho/mainVisual/background-pc.png'
import mainVisualImageSP from '../images/jimbocho/mainVisual/background-sp.png'
import Floor1Spec from '../images/jimbocho/floorMap/floor1_spec.jpg'
import Floor1Image1PC from '../images/jimbocho/floorMap/floor1_image1-pc.png'
import Floor1Image2PC from '../images/jimbocho/floorMap/floor1_image2-pc.png'
import Floor1Image3PC from '../images/jimbocho/floorMap/floor1_image3-pc.png'
import Floor1Image4PC from '../images/jimbocho/floorMap/floor1_image4-pc.png'
import Floor1Image5PC from '../images/jimbocho/floorMap/floor1_image5-pc.png'
import Floor1Image1SP from '../images/jimbocho/floorMap/floor1_image1-sp.png'
import Floor1Image2SP from '../images/jimbocho/floorMap/floor1_image2-sp.png'
import Floor1Image3SP from '../images/jimbocho/floorMap/floor1_image3-sp.png'
import Floor1Image4SP from '../images/jimbocho/floorMap/floor1_image4-sp.png'
import Floor1Image5SP from '../images/jimbocho/floorMap/floor1_image5-sp.png'
import Slide1 from '../images/jimbocho/outline/slide1.jpg'
import Slide2 from '../images/jimbocho/outline/slide2.jpg'
import Slide3 from '../images/jimbocho/outline/slide3.jpg'
import Slide4 from '../images/jimbocho/outline/slide4.jpg'
import Slide5 from '../images/jimbocho/outline/slide5.jpg'

// data
const copy = {
  main: `神保町に小さいながらも\nEFFICEコンセプトが\nぎっしり詰まったオフィス誕生。`,
  sub: `神保町駅徒歩4分の場所に、新しいコンセプトのオフィス\n「ＥＦＦＩＣＥ」が誕生しました。\n神保町ならではの本棚をモチーフにした斬新なデザイン、自然とコミュニケーションが生まれる、仕事に集中できる、わざわざ出社したくなる。\nEFFICEは「企業と共に成長する」オフィスを提供します。`
}

type specItem = {
  heading: string,
  text: string
}
type sliderImages = {
  pc: string[],
  sp: string[],
}
type Content = {
  heading?: string,
  images: sliderImages,
  spec: {
    items: specItem[],
    image: string
  }
};

const contents: Content[] = [
  {
    images: {
      pc: [Floor1Image1PC, Floor1Image2PC, Floor1Image3PC, Floor1Image4PC, Floor1Image5PC],
      sp: [Floor1Image1SP, Floor1Image2SP, Floor1Image3SP, Floor1Image4SP, Floor1Image5SP],
    },
    spec: {
      items: [
        {
          heading: 'welcome/gathering area',
          text: `ギャザリング　4席\n折り畳み式ギャザリング　2席`
        },
        {
          heading: 'web meeting booth/focus booth',
          text: `ブース　2室`
        },
        {
          heading: 'meeting',
          text: `会議室　 1室（6席）`
        },
        {
          heading: 'desk area',
          text: `デスク　9席`
        },
        {
          heading: '水回り',
          text: `女子トイレ　１\n洗面台　1\n男子トイレ　１\nパントリー　１`
        }
      ],
      image: Floor1Spec
    }
  }
]

const data = [
  {
    heading: '物件名',
    text: 'EFFICE神保町'
  },
  {
    heading: 'アクセス',
    text: '東京メトロ半蔵門線・三田線/都営新宿線「神保町駅」徒歩4分（A2番出口）'
  },
  {
    heading: '所在地',
    text: `〒101-0051 東京都千代田区神田神保町２丁目38−11\n岩田ビル1F`
  },
  {
    heading: '竣工',
    text: '1989年'
  },
  {
    heading: '耐震',
    text: '新耐震基準を満たす'
  },
  {
    heading: '坪数',
    text: '20.57坪'
  },
  {
    heading: '入居日',
    text: '募集終了'
  }
]

const link = {
  text: '内覧・お問い合わせはこちら',
  url: 'https://docs.google.com/forms/d/e/1FAIpQLSds7NhBbFIhNvCuE1i2D6JtfdyHpzDSYNnrzCyslXhuibdFKw/viewform',
}

const slide = [
  Slide1, Slide2, Slide3, Slide4, Slide5
]

const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2230.3506737939047!2d139.75407301887373!3d35.697263763680986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c15aa95a043%3A0xdbab5fd22c5d50dd!2z44CSMTAxLTAwNTEg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy656We55Sw56We5L-d55S677yS5LiB55uu77yT77yY4oiS77yR77yRIOWyqeeUsOODk-ODqw!5e0!3m2!1sja!2sjp!4v1654701280321!5m2!1sja!2sjp"

// markup
const IndexPage = () => {
  const isDesktop = useMedia({ minWidth: "768px" });
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const meta = {
    title: '神保町 | EFFICE',
    description: '神保町駅徒歩4分の場所に、新しいコンセプトのオフィス「EFFICE」が誕生しました。神保町ならではの本棚をモチーフにした斬新なデザイン、自然とコミュニケーションが生まれる、仕事に集中できる、わざわざ出社したくなる。EFFICEは「企業と共に成長する」オフィスを提供します。',
    path: '/jimbocho'
  }

  return (
    <main>
      <Seo title={meta.title} description={meta.description} path={meta.path} />
      <Header isDesktop={isDesktop} hidden={inView} />
      <DetailMainVisual copy={copy} mainVisual={isDesktop ? mainVisualImagePC : mainVisualImageSP} />
      <FloorMap isDesktop={isDesktop} contents={contents} autoHeight={true} minHeight={isDesktop ? 750 : 820} />
      <Outline isDesktop={isDesktop} data={data} link={ link } slide={slide} mapUrl={mapUrl} />
      <LineUp />
      <div ref={ref}><Contact isDesktop={isDesktop} /></div>
      <Footer />
    </main>
  )
}

export default IndexPage
